:root {
  --charts-label-color: #313b44;
  --charts-axis-line-color: #f4f5f6;
  --charts-tooltip-title: var(--charts-label-color);
  --charts-tooltip-label: var(--charts-label-color);
  --charts-tooltip-value: #192734;
  --charts-tooltip-bg: #ffffff;
  --charts-stroke-width: 2px;
  --charts-dataset-circle-stroke: #ffffff;
  --charts-dataset-circle-stroke-width: var(--charts-stroke-width);
  --charts-legend-label: var(--charts-label-color);
  --charts-legend-value: var(--charts-label-color);
}

:root {
  --primary-hue: 277;
  --secondary-hue: 29;
  --tertiary-hue: 1;
  --primary-color: hsl(var(--primary-hue),17%,51%);
  --secondary-color: hsl(var(--secondary-hue),92%,69%);
  --tertiary-color: hsl(var(--tertiary-hue),100%,75%);
  --main-hue: 31;
  --light-color: hsl(var(--tertiary-hue),100%,70%);
  --extra-light-color: hsl(var(--tertiary-hue),100%,98%);
  --double-extra-light-color: hsl(var(--tertiary-hue),100%,100%);
  --dark-color: var(--primary-color);
  --extra-dark-color: var(--primary-color);
  --text-dark: #000;
  --text-color: #010020;
  --text-muted: var(--light-color);
  --text-muted: hsl(214deg 97% 10%);
  --text-extra-dark: #f0d676;
  --control-bg-color: hsl(103deg 100% 98%);
  --header-text-color: var(--text-dark);
  --border-color: var(--primary-color);
  --desk-page-bg-color:transparent;
  --body-bg-color: var(--light-color);
  --navbar-bg-color: hsl(var(--primary-hue),20%,90%);
  --navbar-doc-name-color: #000056;
  --header-bg-color: #fbfbff;
  --page-head-bg-color: transparent;
  --footer-bg-color: var(--light-color);
  --navbar-box-shadow-color: hsl(var(--primary-hue),17%,51%);
  --box-shadow-color: hsl(var(--primary-hue),17%,51%);
  --page-container-bg-color: var(--extra-light-color);
  --page-head-bg-color: transparent;
  --page-form-bg-color: var(--extra-light-color);
  --form-inner-toolbar-bg-color: var(--light-color);
  --form-control-bg-color: rgb(255, 255, 255);
  --control-label-text-color: var(--text-color);
  --grid-header-bg-color: var(--header-bg-color);
  --grid-header-text-color: var(--header-text-color);
  --grid-border-color: var(--border-color);
  --list-row-header-bg-color: var(--header-bg-color);
  --focus-bg-color: #ffffff;
  --form-control-focus-bg-color: var(--focus-bg-color);
  --form-control-placeholder-bg-color: var(--focus-bg-color);
  --table-border-color: var(--border-color);
  --highlight-color: var(--focus-bg-color);
  --sidebar-item-label: #ffffff;
  --icon-stroke: #000a44;
}

/* Navigation Bar */
.navbar {
  padding: 0.1rem 1rem;
}

.navbar a {
  font-size: 12px;
  font-weight: bold;
}

.navbar-light {
  background-color: var(--navbar-bg-color) !important;
  box-shadow: 5px 2px 8px var(--extra-dark-color) !important;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--text-color) !important;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link.active {
  color: var(--text-color);
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: var(--text-color);
}

.navbar-default {
  background-color: var(--navbar-bg-color) !important;
  box-shadow: 5px 2px 8px var(--navbar-box-shadow-color) !important;
}

.navbar-default .navbar-nav > li > a {
  color: var(--text-color);
}

.navbar-default .navbar-nav > li > a:hover {
  color: var(--text-dark);
}

.navbar-default .navbar-brand:hover {
  color: var(--text-dark);
}

.navbar-brand {
  float: left;
  height: 40px;
  padding: 10px 15px;
  font-size: 18px;
  line-height: 20px;
}

.brand-name {
  float: right;
  font-weight: bold;
  font-size: 12px;
  text-align: right;
  color: var(--extra-dark-color) !important;
  text-transform: capitalize;
  white-space: nowrap;
}

#navbar-search {
  background-color: var(--form-control-bg-color);
}

/*Body*/
.body {
  background-color: var(--body-bg-color);
}

/*Footer*/
.web-footer {
  padding: 1rem 0 !important;
  bottom: 0 !important;
  width: 100%;
  border-top: 0px !important;
  background-color: var(--navbar-bg-color) !important;
}

.footer-info {
  border-top: none;
  color: var(--text-dark);
}

/*Page Card*/
.login-content .page-card {
  box-shadow: 2px 2px 5px var(--extra-dark-color) !important;
}

.page-card-actions {
  box-shadow: 2px 2px 5px var(--extra-dark-color) !important;
}

#navbar-breadcrumbs li.disabled a {
  color: var(--navbar-doc-name-color);
}

.navbar {
  height: 100px;
}

.navbar-brand {
  float: left;
  height: 100px;
  padding: 0px 10px;
  font-size: 18px;
  line-height: 0px;
}

.navbar-brand img {
  display: inline-block;
  max-width: 150px;
  max-height: 90px;
}